import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { MdPhoneInTalk } from "react-icons/md"
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection3() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className="w-full h-auto bg-grand2 mx-auto justify-center flex items-center pt-64 lg:pt-36 ">
                <div className="w-4/5 h-4/5 flex flex-col-reverse lg:flex-row  justify-center py-0 lg:py-14 ">
                    <div className=" w-full lg:w-1/2 grid place-content-center h-full px-6 py-10">
                        <h1 className=" text-center lg:text-start font-bold">
                            {rpdata?.dbSlogan?.[3].slogan}
                        </h1>
                        <p className="text-center lg:text-start">{rpdata?.dbValues?.[1].description}</p>
                        <div className="lg:block flex justify-center md:justify-start lg:justify-start mx-auto lg:mx-0">
                            <ButtonContent />
                        </div>
                    </div>
                    <div className=" w-full h-auto lg:h-auto lg:w-[30%]  flex mx-auto justify-center lg:justify-start items-center static lg:relative">
                        <div className="w-[80px] lg:w-[200px] h-[80px] lg:h-[200px] borderColor border-[15px] lg:border-[35px] rounded-full absolute top-[35%] lg:top-[45%] left-[10%] lg:left-[-5%] shadow-2xl "></div>
                        <div className="hidden lg:flex static lg:absolute  lg:w-[430px] lg:h-[430px] w-[250px] h-[250px] bg-white border border-green-800 rounded-full top-[-5%] left-[12%] shadow-2xl z-[2]">
                            <figure
                                className="pic-1"
                                style={{ background: `url("${rpdata?.gallery?.[1]}")` }}
                            ></figure>
                            <figure
                                className="pic-2"
                                style={{ background: `url("${rpdata?.stock?.[8]}")` }}
                            ></figure>
                            <figure
                                className="pic-3"
                                style={{ background: `url("${rpdata?.stock?.[3]}")` }}
                            ></figure>
                            <figure
                                className="pic-4"
                                style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
                            ></figure>
                        </div>
                        <div className="flex md:hidden static lg:absolute  lg:w-[430px] lg:h-[430px] w-[300px] h-[300px] bg-white border border-green-800 rounded-full top-[-5%] left-[12%] shadow-2xl z-[2] bg-cover bg-center" style={{ background: `url("${rpdata?.stock?.[5]}")`, backgroundSize:"cover" }}>
                            
                        </div>
                    </div>
                    <div className=" relative w-[20%] flex justify-center items-center">
                        <div className="absolute rounded-full w-[60px] lg:w-[100px] h-[60px] lg:h-[100px] bg-2 shadow-lg bottom-[-310px] lg:bottom-[26%] left-[200%] lg:left-[16%] z-[3] text-white border-[5px] border-white flex justify-center items-center">
                            <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}>
                                <MdPhoneInTalk className=" text-[30px]" />
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export default HeroSection3;